<template>
  <div class="he-r">
    <div class="dynamic-con">
      <h5 class="he-tit">动态圈</h5>
      <ul class="dynamic-list">
        <li v-for="(item, i) in dynamicList" :key="i">
          <span
            class="head-imgs"
            :style="{
              background: 'url(' + headIcon + ') no-repeat center center',
              backgroundSize: 'cover'
            }"
          ></span>
          <div class="dynamic-r">
            <h5 class="he-name-tit">
              {{ item.titi
              }}<i
                class="level-icon"
                :style="{
                  background: 'url(' + level + ') no-repeat center center',
                  backgroundSize: 'cover'
                }"
              ></i>
            </h5>
            <span class="dynamic-time">{{ item.createTime }}</span>
            <p>{{ item.con }}</p>
            <div class="dynamic-img">
              <template v-if="item.blogType == 1">
                <div>
                  <div class="more-list">
                    <ul>
                      <li v-for="(img, j) in item.bolgImgs.split(',')" :key="j">
                        <img :src="img" />
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="img-one">
                                  <img :src="item.bolgImgs" />
                                </div> -->
                </div>
              </template>
              <video
                v-else-if="item.blogType == 2"
                class="video-box"
                :src="item.blogVideo"
                controls
                preload="metadata"
              />
            </div>
           <!-- <div class="dynamic-view-box">
                            <div class="dynamic-view">
                              <i class="hand-i"></i>
                              <span>点赞</span>
                              <span>{{ item.num }}</span>
                            </div>
                            <div class="dynamic-view">
                              <i class="view-i"></i>
                              <span>浏览</span>
                              <span>{{ item.view }}</span>
                            </div>
                          </div> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    headIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOn: true,
      level: require('../../assets/images/level.png'),
      dynamicList: [
        // {
        //   headicon: require('../../assets/images/people.png'),
        //   level: require('../../assets/images/level.png'),
        //   titi: '朱世亮',
        //   time: '2020-10-13   12:25',
        //   con:
        //     '建瓯挑幡是闽北特有的民间绝活，相传当年追随郑成功收复台湾归来的建瓯将士将带回的军旗捆在竹竿上尽情挥舞，以此纪念英烈，后逐渐演变成建瓯挑幡习俗',
        //   num: '234',
        //   view: '543',
        //   on: true,
        //   vif: 'more',
        //   imgList: [
        //     {
        //       img: require('../../assets/images/view-img3.png')
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png')
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png')
        //     },
        //     {
        //       img: require('../../assets/images/view-img3.png')
        //     }
        //   ]
        // },
        // {
        //   headicon: require('../../assets/images/people.png'),
        //   level: require('../../assets/images/level.png'),
        //   titi: '朱世亮',
        //   time: '2020-10-13   12:25',
        //   con: '南平市建瓯人，1956年8月生，国家级非物质文化遗产扩展项目中幡（建瓯挑幡）第五批省级代表性传承人。',
        //   num: '234',
        //   on: false,
        //   view: '543',
        //   vif: 'vedio',
        //   imgList: [
        //     {
        //       img: require('../../assets/images/view-img1.jpg')
        //     }
        //   ]
        // },
        // {
        //   headicon: require('../../assets/images/people.png'),
        //   level: require('../../assets/images/level.png'),
        //   titi: '朱世亮',
        //   time: '2020-10-13   12:25',
        //   con: '南平市建瓯人，1956年8月生，国家级非物质文化遗产扩展项目中幡（建瓯挑幡）第五批省级代表性传承人。',
        //   num: '234',
        //   view: '543',
        //   on: false,
        //   vif: 'one',
        //   imgList: [
        //     {
        //       img: require('../../assets/images/view-img2.png')
        //     }
        //   ]
        // }
      ]
    }
  },
  methods: {
    ...mapMutations(['setPostUpdated']),
    ison() {
      this.isOn = !this.isOn
    },
    getDynamicList() {
      this.axios.get('/api/portal/blog/auth/page-my-content', {}).then(res => {
        this.dynamicList = res.data.records
      })
    }
  },
  mounted() {
    this.getDynamicList()
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  },
  computed: {
    ...mapState(['postUpdated'])
  },
  watch: {
    postUpdated(postUpdated) {
      if (!postUpdated) {
        this.getDynamicList()
        this.setPostUpdated(true)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
